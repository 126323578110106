import React from "react";
import HeroComponent from "../components/ImageHero.js";
import PageWrapper from "../components/PageWrapper";
import BlogList from "../sections/blog/BlogListSidebar";
import imgVideo from "../assets/image/jpeg/about-d.jpg";
import Trend from '../components/trends.js';


const BlogRightSidebar = () => {
  return (
    <>
      <PageWrapper footerDark>
    
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Dermamina </span> <span style="color: #00aec7;">Blog</span>'
    secondText="Latest From Our Blog"
    videoAlt="Blog Non Surgical Treatments"
    imgVideo={imgVideo} // Specify your image URL here
  />

 
          
                <BlogList />
              
          <Trend />
    
      </PageWrapper>
    </>
  );
};
export default BlogRightSidebar;
